import { translate } from "@ollie-sports/i18n";
import { OpenOrgEventRegistrationQuestionType, OrgRegistrationQuestionType } from "@ollie-sports/models";

export const APP_VERSION = "3.1.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN"
};

export const registrationQuestionTypeLabels: Record<OrgRegistrationQuestionType, string> = {
  "free-response": translate({ defaultMessage: "Free Response" }),
  "legal-doc": translate({ defaultMessage: "Waiver/Legal Document" }),
  explainer: translate({
    defaultMessage: "Explainer",
    description: "An explainer is a somewhat short snippet of text that explains something"
  }),
  checkbox: translate({ defaultMessage: "Multiple Select" }),
  radio: translate({ defaultMessage: "Multiple Choice" }),
  additionalStep: translate({ defaultMessage: "Additional Registration Step" })
};
