import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import "src/mixins/chartjs";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "src/store";
import { SettingsProvider } from "src/contexts/SettingsContext";
import App from "src/App";

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById("root")
);
